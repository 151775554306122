import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const CircularProgressBar: React.FC<{ data: number }> = ({ data }) => {
	return (
		<Box position="relative" display="inline-flex">
			{/* Background Circle */}
			<CircularProgress
				variant="determinate"
				value={100}
				size={300}
				thickness={8}
				sx={{
					color: "#e0e0e0",
				}}
			/>
			{/* Progress Circle */}
			<CircularProgress
				variant="determinate"
				value={data}
				size={300}
				thickness={8}
				sx={{
					color: "#486492",
					position: "absolute",
					left: 0,
				}}
			/>
			{/* Centered Text */}
			<Box
				position="absolute"
				top="50%"
				left="50%"
				textAlign="center"
				sx={{
					transform: "translate(-50%, -50%)",
				}}
			>
				<Typography fontSize="24px" fontWeight={600} color="textPrimary">
					{data}/100
				</Typography>
				<Typography fontSize="18px" color="textPrimary">
					Overall Score
				</Typography>
			</Box>
		</Box>
	);
};

export default CircularProgressBar;
