import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { IExecutionGeoSummaryData, IMnAFilterConfigurations, ISummaryGeoFilter } from "../../../../types/mixAndAssortment";
import dataObject from "../../../../mocks/executionTracker";
import Indicator from "../../../../components/Loader";
import DataGridWithFilters from "../../../../components/DataGridWithFilters";
import Dropdown from "../../../../components/Dropdown";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import { IconDownload } from "../../../../assets/icons/mna";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { convertToCsvWithKeys, downloadInCsvFormat } from "../../../../util/mixAndAssortmentServices";
import { columnsToDownloadGeoTable, geoLevelSummaryColumns } from "../ExecutionTable/TableConfig";

const filterTableColumns = (level: string) => {
	const exclusionMap: Record<string, number[]> = {
		Channel: [1, 2],
		Region: [2],
	};
	const exclusions = exclusionMap[level] || [];
	const downloadColumns = columnsToDownloadGeoTable.filter((_, index) => !exclusions.includes(index));
	const viewColumns = geoLevelSummaryColumns.filter((_, index) => !exclusions.includes(index));
	return { downloadColumns, viewColumns };
};

const disableApplyBtn = (selectedFilters: IMnAFilterConfigurations) => {
	let flag: boolean = true;
	if (
		selectedFilters.assortmentScenario?.length &&
		selectedFilters.businessUnit.length &&
		selectedFilters.channel.length &&
		selectedFilters.country.length &&
		selectedFilters.dateTimePeriod.length &&
		selectedFilters.mixScenario?.length &&
		selectedFilters.region.length &&
		selectedFilters.storeSegment.length &&
		selectedFilters.geoSummary?.level &&
		selectedFilters.geoSummary?.segment.length &&
		selectedFilters.geoSummary?.brand.length &&
		selectedFilters.geoSummary?.sku.length
	)
		flag = false;
	return flag;
};
const GeoLevelSummary: React.FC<{
	selectedFilters: IMnAFilterConfigurations;
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
	originalGeoFilterData;
	tableData: IExecutionGeoSummaryData[];
	onApplyFilter: () => void;
	skeleton: boolean;
	loader: Boolean;
}> = ({ selectedFilters, originalGeoFilterData, setSelectedFilters, tableData, onApplyFilter, skeleton, loader }) => {
	const [geoFilterOption, setGeoFilterOption] = useState<ISummaryGeoFilter>(dataObject.overallFilterOptions.geoSummary!);
	const [geoFilterData, setGeoFilterData] = useState(dataObject.geoSummaryFilter);
	const [defaultFilterOptions, setDefaultFilterOptions] = useState<ISummaryGeoFilter>(dataObject.overallFilterOptions.geoSummary!);
	const [levelFilteredColumns, setLevelFilteredColumns] = useState(geoLevelSummaryColumns);
	const [downloadColumns, setDownloadColumns] = useState(columnsToDownloadGeoTable);

	const onChangeGeoFilter = (key, value) => {
		let selectedGeoValues: ISummaryGeoFilter = {
			...(selectedFilters.geoSummary ?? {
				level: "",
				segment: [],
				brand: [],
				sku: [],
			}),
		};
		switch (key) {
			case "level": {
				selectedGeoValues = {
					...selectedGeoValues,
					level: value,
				};
				break;
			}
			case "segment": {
				selectedGeoValues = {
					...selectedGeoValues,
					segment: value,
					brand: [],
					sku: [],
				};
				break;
			}
			case "brand": {
				selectedGeoValues = {
					...selectedGeoValues,
					brand: value,
					sku: [],
				};
				break;
			}
			case "sku": {
				selectedGeoValues = {
					...selectedGeoValues,
					sku: value,
				};
				break;
			}
		}
		const selectedValues: IMnAFilterConfigurations = { ...selectedFilters, geoSummary: selectedGeoValues };
		setSelectedFilters(selectedValues);
	};

	const clearFilter = () => {
		const selectedValues = { ...selectedFilters, geoSummary: defaultFilterOptions };
		setSelectedFilters(selectedValues);
	};

	const downloadGeoSummaryListData = () => {
		const keysArray = downloadColumns.map((column) => column.field);
		const csvData = convertToCsvWithKeys(tableData, keysArray, downloadColumns);
		downloadInCsvFormat(csvData, "Execution_Geo_Level_Summary_Table_Data");
	};

	useEffect(() => {
		if (originalGeoFilterData) {
			let segmentFilterOptions: string[] = [];
			segmentFilterOptions = Array.from(
				new Set(
					originalGeoFilterData.map((geoFilters) => {
						return geoFilters.segment;
					})
				)
			);
			segmentFilterOptions = segmentFilterOptions.sort();
			let brandFilterOptions: string[] = [];
			let selectedGeoFilters = originalGeoFilterData.filter((geoFilters) => {
				return selectedFilters.geoSummary?.segment.indexOf(geoFilters.segment) !== -1;
			});
			brandFilterOptions = Array.from(
				new Set(
					selectedGeoFilters.map((geoFilters) => {
						return geoFilters.brand;
					})
				)
			);
			brandFilterOptions = brandFilterOptions.sort();
			let skuFilterOptions: string[] = [];
			selectedGeoFilters = selectedGeoFilters.filter((geoFilters) => {
				return selectedFilters.geoSummary?.brand.indexOf(geoFilters.brand) !== -1;
			});
			skuFilterOptions = Array.from(
				new Set(
					selectedGeoFilters.map((geoFilters) => {
						return geoFilters.sku;
					})
				)
			);
			skuFilterOptions = skuFilterOptions.sort();
			setGeoFilterOption({
				...geoFilterOption,
				brand: brandFilterOptions,
				segment: segmentFilterOptions,
				sku: skuFilterOptions,
			});
			setGeoFilterData({
				...geoFilterData,
				segment: { ...geoFilterData.segment, disabled: false },
				brand: { ...geoFilterData.brand, disabled: brandFilterOptions.length === 0 },
				sku: { ...geoFilterData.sku, disabled: skuFilterOptions.length === 0 },
			});
			if (defaultFilterOptions === dataObject.overallFilterOptions.geoSummary!)
				setDefaultFilterOptions({
					level: "Store Segment",
					brand: brandFilterOptions,
					segment: segmentFilterOptions,
					sku: skuFilterOptions,
				});
		} else {
			setGeoFilterOption({
				level: "Store Segment",
				brand: [],
				segment: [],
				sku: [],
			});
			setGeoFilterData({
				...geoFilterData,
				segment: { ...geoFilterData.segment, disabled: true },
				brand: { ...geoFilterData.brand, disabled: true },
				sku: { ...geoFilterData.sku, disabled: true },
			});
		}
	}, [selectedFilters]);

	useEffect(() => {
		if (originalGeoFilterData) {
			let segmentFilterOptions: string[] = [];
			segmentFilterOptions = Array.from(
				new Set(
					originalGeoFilterData.map((geoFilters) => {
						return geoFilters.segment;
					})
				)
			);
			segmentFilterOptions = segmentFilterOptions.sort();
			let brandFilterOptions: string[] = [];
			brandFilterOptions = Array.from(
				new Set(
					originalGeoFilterData.map((geoFilters) => {
						return geoFilters.brand;
					})
				)
			);
			brandFilterOptions = brandFilterOptions.sort();
			let skuFilterOptions: string[] = [];
			skuFilterOptions = Array.from(
				new Set(
					originalGeoFilterData.map((geoFilters) => {
						return geoFilters.sku;
					})
				)
			);
			skuFilterOptions = skuFilterOptions.sort();
			setGeoFilterOption({
				...geoFilterOption,
				brand: brandFilterOptions,
				segment: segmentFilterOptions,
				sku: skuFilterOptions,
			});
			setGeoFilterData({
				...geoFilterData,
				segment: { ...geoFilterData.segment, disabled: false },
				brand: { ...geoFilterData.brand, disabled: brandFilterOptions.length === 0 },
				sku: { ...geoFilterData.sku, disabled: skuFilterOptions.length === 0 },
			});
			setDefaultFilterOptions({
				level: "Store Segment",
				brand: brandFilterOptions,
				segment: segmentFilterOptions,
				sku: skuFilterOptions,
			});
			selectedFilters.geoSummary = {
				level: "Store Segment",
				brand: brandFilterOptions,
				segment: segmentFilterOptions,
				sku: skuFilterOptions,
			};
			setSelectedFilters(selectedFilters);
			onApplyFilter();
		}
	}, [originalGeoFilterData]);

	useEffect(() => {
		if (tableData) {
			const columnDetails = filterTableColumns(selectedFilters.geoSummary?.level!);
			setLevelFilteredColumns(columnDetails.viewColumns);
			setDownloadColumns(columnDetails.downloadColumns);
		}
	}, [tableData]);

	return (
		<>
			<Card>
				<CardContent>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Title className="ellipsis">Geo Level Summary</Title>
						<Stack direction="row" alignItems="center" spacing={4}>
							<Stack direction="row" alignItems="center" spacing={1}>
								<DropdownTitle>Level:</DropdownTitle>
								<Box width={130}>
									<Dropdown
										keyText="table-sub-heading"
										data={dataObject.geoLevelFilterData}
										multiple={false}
										onChange={(data) => onChangeGeoFilter("level", data[0])}
										placeholder="Level"
										defaultOption={selectedFilters.geoSummary?.level}
									/>
								</Box>
							</Stack>
							<Grid container>
								<Grid item style={{ marginLeft: "auto" }}>
									<PrimaryBtn onClick={downloadGeoSummaryListData} disabled={disableApplyBtn(selectedFilters) && tableData.length === 0}>
										<Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
											<IconDownload />
											<Grid marginLeft={"5px"}>Download CSV</Grid>
										</Grid>
									</PrimaryBtn>
								</Grid>
							</Grid>
						</Stack>
					</Stack>
					<Stack direction="row" className="m-t-20 m-l-20">
						<Box width="700px">
							<CommonMnAFilters
								filterData={geoFilterOption}
								onChange={onChangeGeoFilter}
								data={geoFilterData}
								defaultFilters={selectedFilters.geoSummary}
								filterOrder={dataObject.geoSummaryFilterOrder}
							/>
						</Box>
						<Grid className="p-l-16" marginBottom={5} mt={3}>
							<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
								Reset Filter
							</OrangeBtn>
							<PrimaryBtn color="primary" onClick={onApplyFilter} disabled={disableApplyBtn(selectedFilters)}>
								Apply Filter
							</PrimaryBtn>
						</Grid>
					</Stack>
					{!skeleton ? (
						<Box height={"60vh"} overflow={"auto"} p={2}>
							{loader ? (
								<Indicator position={"relative"} show={loader} />
							) : (
								<DataGridWithFilters columns={levelFilteredColumns} rows={tableData} noRowsMessage="No data available" />
							)}
						</Box>
					) : (
						<Grid item xs={12}>
							<Skeleton variant="rectangular" height={250} />
						</Grid>
					)}
				</CardContent>
			</Card>
		</>
	);
};

export default GeoLevelSummary;
