import React, { FC } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import SimulationCard from "./SimulationCard";
import { TopHeading } from "../../../ElasticityPricingTrack/ElasticityPricingTrack.Styled";
import SizeOfPrize from "./SizeOfPrize";
import OverviewKPI from "./OverviewKPI";
import Indicator from "../../../../components/Loader";

const Overview: FC<{ opportunityData; simulationData; overviewKPIData; chartSkeleton: boolean; loader: Boolean }> = ({
	opportunityData,
	simulationData,
	overviewKPIData,
	chartSkeleton,
	loader,
}) => {
	return (
		<>
			<Card className="m-b-20" style={{ position: "relative" }}>
				<Indicator position={"absolute"} show={loader} />
				<CardContent>
					<Title className="ellipsis m-b-20">Overview</Title>
					<Grid container spacing={2} marginTop={1} className="m-b-20" display="flex" alignItems="center">
						<Grid item sm={12}>
							<OverviewKPI data={overviewKPIData} chartSkeleton={chartSkeleton} />
						</Grid>
					</Grid>
					<Grid container spacing={2} marginTop={1}>
						<Grid item sm={9}>
							<TopHeading>Execution Score</TopHeading>
						</Grid>
						<Grid item sm={3}>
							<TopHeading>Size of Prize (Avg. Monthly)</TopHeading>
						</Grid>
					</Grid>
					<Grid container spacing={2} marginTop={1}>
						<Grid item sm={9} sx={{ paddingLeft: "16px" }}>
							<Grid display={"flex"} sx={{ border: "3px solid #CAD0D8", borderRadius: "10px", padding: "20px", height: 470 }}>
								<SimulationCard data={simulationData} chartSkeleton={chartSkeleton} />
							</Grid>
						</Grid>
						<Grid item sm={3} xs={12} sx={{ paddingLeft: "16px" }}>
							<Grid sx={{ border: "3px solid #CAD0D8", borderRadius: "10px", padding: "10px", height: 470, overflowY: "auto" }}>
								<SizeOfPrize data={opportunityData} chartSkeleton={chartSkeleton} />
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};

export default Overview;
