import { IMixSelectedFilters } from "../../../types/mixAndAssortment";

export const GET_MIX_OVERALL_FILTER_DATA_QUERY = {
	query: `query {
    getFilterData(dashboardName: "Mix") {
      country
      dateTimePeriod
      endTimePeriod
      businessUnit
    }
  }`,
};

export const GET_MIX_GEO_FILTER_DATA_QUERY = (country: string, dateTimePeriod: string, businessUnit: string, endTimePeriod: string) => ({
	query: `query {
    getMixGeoFilterData(
      dashboardName: "Mix"
      country: "${country}"
      dateTimePeriod: "${dateTimePeriod}"
      businessUnit: "${businessUnit}"
      endTimePeriod: "${endTimePeriod}"
    ) {
      level
      channel {
        value
        level
      }
      region {
        value
        level
        channel
      }
      storeSegment {
        value
        level
        channel
        region
      }
    }
  }`,
});

export const GET_MIX_ASSORTMENT_SCENARIOS_OPTIONS_QUERY = (country: string, businessUnit: string) => ({
	query: `query {
    getAssortmentScenarios(
      country: "${country}"
      businessUnit: "${businessUnit}"
    ) {
      scenarioName
    }
  }`,
});

export const GET_MIX_CONSTRAINTS_QUERY = (selectedFilters: IMixSelectedFilters, userInputs: string) => ({
	query: `query {
    getMixConstraints(
      country: "${selectedFilters.country}"
      dateTimePeriod: "${selectedFilters.dateTimePeriod}"
      businessUnit: "${selectedFilters.businessUnit}"
      endTimePeriod: "${selectedFilters.endTimePeriod}"
      userInputs: ${JSON.stringify(userInputs)}
    ) {
      channel
      region
      storeSegment
      segment
      brand
      subBrand
      packSize
      flavor
      sku
      minMix
      maxMix
    }
  }`,
});

export const CHECK_UPLOADED_MIX_CONSTRAINTS_QUERY = (selectedFilters: IMixSelectedFilters, userInputs: string, mixConstraints: string) => ({
	query: `query {
    getDownloadMixConstraints(
      country: "${selectedFilters.country}"
      dateTimePeriod: "${selectedFilters.dateTimePeriod}"
      businessUnit: "${selectedFilters.businessUnit}"
      endTimePeriod: "${selectedFilters.endTimePeriod}"
      userInputs: ${JSON.stringify(userInputs)}
      mixConstraints: ${JSON.stringify(mixConstraints)}
    ) {
      success
    }
  }`,
});

export const SAVE_MIX_SCENARIO = (
	scenarioName: string,
	isNewScenario: boolean,
	bypassDuplicateCheck: boolean,
	userFullName: string,
	selectedFilters: IMixSelectedFilters,
	userInputs: string,
	mixConstraints: string
) => ({
	query: `mutation {
    editMixScenario(
      isNewScenario: "${isNewScenario ? "Y" : "N"}"
      bypassDuplicateCheck: "${bypassDuplicateCheck ? "Y" : "N"}"
      scenarioName: "${scenarioName}"
      createdBy: "${userFullName}"
      country: "${selectedFilters.country}"
      dateTimePeriod: "${selectedFilters.dateTimePeriod}"
      businessUnit: "${selectedFilters.businessUnit}"
      endTimePeriod: "${selectedFilters.endTimePeriod}"
      goal: "${selectedFilters.goal}"
      userInputs: ${JSON.stringify(userInputs)}
      mixConstraints: ${JSON.stringify(mixConstraints)}
    ) {
      success
      duplicate
      message
    }
  }`,
});

export const CHECK_MIX_SCENARIO = (scenarioName: string) => ({
	query: `query {
    viewScenario(scenarioName: "${scenarioName}") {
      isExpired
      isInProgress
    }
  }`,
});

export const GET_MIX_SELECTED_FILTERS = (scenarioName: string) => ({
	query: `query {
    getScenarioDetails(scenarioName: "${scenarioName}") {
      country
      dateTimePeriod
      businessUnit
      endTimePeriod
      userInputs
      goal
    }
  }`,
});

export const GET_MIX_CONSTRAINTS_FOR_SCENARIO = (scenarioName: string) => ({
	query: `query {
    getMixEditConstraints(scenarioName: "${scenarioName}") {
      channel
      region
      storeSegment
      segment
      brand
      subBrand
      packSize
      flavor
      sku
      minMix
      maxMix
    }
  }`,
});

export const GET_MIX_SUMMARY_DATA_QUERY = (scenarioName: string) => ({
	query: `query {
    getSummaryData(scenarioName: "${scenarioName}") {
      chunkSummaryData
    }
  }`,
});

export const GET_MIX_PORTFOLIO_AND_GEO_DATA_QUERY = (scenarioName: string) => ({
	query: `query {
    getMixPortfolioData(scenarioName: "${scenarioName}") {
      chunkMixPortfolioData
    }
  }`,
});

export const GET_MIX_SCENARIO_DOWNLOAD_DATA_QUERY = (scenarioName: string) => ({
	query: `query {
    getDownloadMixSummaryData(scenarioName: "${scenarioName}") {
      success
      message
      data
    }
  }`,
});
