import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import pako from "pako";
import {
	IAssortmentPenetrationLimit,
	IExecutionGeoSummaryData,
	IExecutionPortfolioTableData,
	IExecutionTopFilterConfigurations,
	IExecutionTrackerPayload,
	IMnAFilterConfigurations,
	IPortfolioGeoFilter,
} from "../../types/mixAndAssortment";
import {
	getExecutionTrackerGeoLevelFilterData,
	getExecutionTrackerGeoLevelTableData,
	getExecutionTrackerOpportunityData,
	getExecutionTrackerOverviewKPIData,
	getExecutionTrackerPortfolioTableData,
	getExecutionTrackerSimulationData,
} from "../../util/mixAndAssortmentServices";
import { pageNames } from "../../mocks/common";
import { dialog } from "../../store/actions/dialog.action";
import PageContainer from "../../components/PageContainer";
import TopFilters from "./components/Filters/TopFilters";
import Overview from "./components/Overview/Overview";
import Portfolio from "./components/Portfolio/Portfolio";
import GeoLevelSummary from "./components/GeoLevelSummary/GeoLevelSummary";
import { Title } from "../../styles/Common.Styled";

const transformPortfolioData = (data: IExecutionPortfolioTableData[]): IExecutionPortfolioTableData[] => {
	return data.map((item, index) => ({
		...item,
		id: index,
		revenuMix: item.revenuMix * 100,
	}));
};

const transformGeoLevelData = (data: IExecutionGeoSummaryData[]): IExecutionGeoSummaryData[] => {
	return data.map((item, index) => ({
		...item,
		id: index,
		revenuMix: item.revenuMix * 100,
	}));
};

const decryptExecutionData = (data: any) => {
	let finalData: any[] = [];
	data.forEach((item: any) => {
		if (item.chunkExecutionPortfolioTableData || item.chunkGeoLevelSummaryTableData) {
			const binaryString = atob(item.chunkExecutionPortfolioTableData || item.chunkGeoLevelSummaryTableData);
			const binaryData = new Uint8Array(binaryString.split("").map((char) => char.charCodeAt(0)));
			const deCompressedData = pako.inflate(binaryData, { to: "string" });
			let jsonData = JSON.parse(deCompressedData);
			if (typeof jsonData === "string") {
				jsonData = JSON.parse(jsonData);
			}
			finalData = [...finalData, ...jsonData];
		}
	});
	return finalData;
};

const ExecutionTracker: FC = () => {
	const dispatch = useDispatch();
	const [selectedFilters, setSelectedFilters] = useState<IMnAFilterConfigurations>({
		country: [],
		dateTimePeriod: [],
		businessUnit: [],
		endTimePeriod: [],
		region: [],
		channel: [],
		storeSegment: [],
		mixScenario: [],
		assortmentScenario: [],
		portfolioGeo: {
			level: "Brand",
			channel: [],
			region: [],
			storeSegment: [],
		},
		geoSummary: {
			level: "Store Segment",
			segment: [],
			brand: [],
			sku: [],
		},
	});
	const [selectedPenetrationLimit, setSelectedPenetrationLimit] = useState<IAssortmentPenetrationLimit>({
		mustHaveSKU: 0,
		mustHaveSkuCurrentAverage: 0,
		goodToHaveSKU: 0,
		goodToHaveSkuCurrentAverage: 0,
	});
	const [simulationData, setSimulationData] = useState(null);
	const [opportunityData, setOpportunityData] = useState(null);
	const [overviewKPIData, setOverviewKPIData] = useState(null);
	const [originalGeoFilterData, setOriginalGeoFilterData] = useState<IExecutionTopFilterConfigurations[]>([]);
	const [portfolioFilterData, setPortfolioFilterData] = useState<any>(null);
	const [portfolioTableData, setPortfolioTableData] = useState<IExecutionPortfolioTableData[]>([]);
	const [geoLevelFilterData, setGeoLevelFilterData] = useState<any>(null);
	const [geoLevelTableData, setGeoLevelTableData] = useState<IExecutionGeoSummaryData[]>([]);
	const [skeleton, setSkeleton] = useState({
		portfolio: true,
		geoLevel: true,
		overview: true,
	});
	const [loader, setLoader] = useState({
		portfolio: false,
		geoLevel: false,
		overview: false,
	});

	const resetData = () => {
		setPortfolioTableData([]);
		setGeoLevelTableData([]);
		setOpportunityData(null);
		setOverviewKPIData(null);
		setSimulationData(null);
		setGeoLevelFilterData(null);
		setPortfolioFilterData(null);
		setSkeleton({
			portfolio: true,
			geoLevel: true,
			overview: true,
		});
	};

	//Creates payload format against selected filters
	const formatPayload = () => {
		const payload: IExecutionTrackerPayload = {
			country: selectedFilters.country[0],
			dateTimePeriod: selectedFilters.dateTimePeriod[0],
			businessUnit: selectedFilters.businessUnit[0],
			endTimePeriod: selectedFilters.endTimePeriod[0],
			channel: selectedFilters.channel,
			region: selectedFilters.region,
			storeSegment: selectedFilters.storeSegment,
			assortmentScenarioName: selectedFilters.assortmentScenario ? selectedFilters.assortmentScenario[0] : "",
			mixScenarioName: selectedFilters.mixScenario ? selectedFilters.mixScenario[0] : "",
			goodToHaveSkus: selectedPenetrationLimit.goodToHaveSKU,
			mustHaveSKUs: selectedPenetrationLimit.mustHaveSKU,
			portfolioGeoFilter: selectedFilters.portfolioGeo,
			summaryGeoFilter: selectedFilters.geoSummary,
		};
		return payload;
	};

	const fetchPortfolioTableData = () => {
		setLoader({
			...loader,
			portfolio: true,
		});
		setPortfolioTableData([]);
		const payload: IExecutionTrackerPayload = formatPayload();
		getExecutionTrackerPortfolioTableData(payload)
			.then((response) => {
				if (response.data) {
					const decryptedData = decryptExecutionData(response.data);
					setPortfolioTableData(transformPortfolioData(decryptedData));
				} else if (response.error) dispatch(dialog(response.error, "Portfolio Table Data: ", true, true));
			})
			.finally(() => {
				setLoader({
					...loader,
					portfolio: false,
				});
			});
	};

	const fetchGeoLevelSummaryTableData = async () => {
		setLoader({
			...loader,
			geoLevel: true,
		});
		setGeoLevelTableData([]);
		const payload: IExecutionTrackerPayload = formatPayload();
		await getExecutionTrackerGeoLevelTableData(payload).then((response) => {
			if (response.data) {
				const decryptedData = decryptExecutionData(response.data);
				setGeoLevelTableData(transformGeoLevelData(decryptedData));
			} else if (response.error) dispatch(dialog(response.error, "Geo Level Table Data: ", true, true));
		});
		setLoader({
			...loader,
			geoLevel: false,
		});
	};

	const runSimulation = async () => {
		setSkeleton({
			portfolio: false,
			geoLevel: false,
			overview: false,
		});
		setLoader({
			portfolio: true,
			geoLevel: true,
			overview: true,
		});
		const portfolioFilter: IPortfolioGeoFilter = {
			level: "Brand",
			channel: selectedFilters.channel,
			region: selectedFilters.region,
			storeSegment: selectedFilters.storeSegment,
		};
		selectedFilters.portfolioGeo = portfolioFilter;
		setSelectedFilters(selectedFilters);
		const payload: IExecutionTrackerPayload = formatPayload();
		const portfolioFilteredData = originalGeoFilterData.filter(
			(item) =>
				selectedFilters.channel.includes(item.channel) &&
				selectedFilters.region.includes(item.region) &&
				selectedFilters.storeSegment.includes(item.storeSegment)
		);
		setPortfolioFilterData(portfolioFilteredData);
		await getExecutionTrackerGeoLevelFilterData(payload).then((response) => {
			if (response.data) setGeoLevelFilterData(response.data);
			else if (response.error) dispatch(dialog(response.error, "Geo-Level Filter: ", true, true));
		});
		await getExecutionTrackerSimulationData(payload).then((response) => {
			if (response.data) setSimulationData(response.data);
			else if (response.error) dispatch(dialog(response.error, "Execution Simulation Data: ", true, true));
		});
		await getExecutionTrackerOpportunityData(payload).then((response) => {
			if (response.data) setOpportunityData(response.data);
			else if (response.error) dispatch(dialog(response.error, "Execution Opportunity Data: ", true, true));
		});
		await getExecutionTrackerOverviewKPIData(payload).then((response) => {
			if (response.data) setOverviewKPIData(response.data);
			else if (response.error) dispatch(dialog(response.error, "Overview KPI Data: ", true, true));
		});
		await getExecutionTrackerPortfolioTableData(payload).then((response) => {
			if (response.data) {
				const decryptedData = decryptExecutionData(response.data);
				setPortfolioTableData(transformPortfolioData(decryptedData));
			} else if (response.error) dispatch(dialog(response.error, "Portfolio Table Data: ", true, true));
		});
		setLoader({
			...loader,
			portfolio: false,
			overview: false,
		});
	};
	return (
		<PageContainer page={pageNames.executionTracker}>
			<Title className="m-b-20" style={{ fontSize: 24, fontWeight: 600 }}>
				Execution Tracker
			</Title>
			<TopFilters
				setSelectedFilters={setSelectedFilters}
				selectedFilters={selectedFilters}
				callBack={runSimulation}
				selectedPenetrationLimit={selectedPenetrationLimit}
				setSelectedPenetrationLimit={setSelectedPenetrationLimit}
				setOriginalGeoFilterData={setOriginalGeoFilterData}
				originalGeoFilterData={originalGeoFilterData}
				resetData={resetData}
			/>
			<Overview
				opportunityData={opportunityData}
				simulationData={simulationData}
				overviewKPIData={overviewKPIData}
				chartSkeleton={skeleton.overview}
				loader={loader.overview}
			/>
			<Portfolio
				tableData={portfolioTableData}
				onApplyFilter={fetchPortfolioTableData}
				setSelectedFilters={setSelectedFilters}
				originalGeoFilterData={portfolioFilterData}
				selectedFilters={selectedFilters}
				skeleton={skeleton.portfolio}
				loader={loader.portfolio}
			/>
			<GeoLevelSummary
				setSelectedFilters={setSelectedFilters}
				selectedFilters={selectedFilters}
				originalGeoFilterData={geoLevelFilterData}
				tableData={geoLevelTableData}
				onApplyFilter={fetchGeoLevelSummaryTableData}
				skeleton={skeleton.geoLevel}
				loader={loader.geoLevel}
			/>
		</PageContainer>
	);
};

export default ExecutionTracker;
